import { React, useEffect, useState } from 'react';
import { BarChart } from "../../assets/BarChart";

export default function AnswersSingAndMultipl({ counts, initialChartDatasets }) {
    return (
        <div className="flex flex-row flex-auto justify-between">
            <div className='flex-auto flex-col items-center' style={{maxWidth: '50%'}}>
                <BarChart datasets={initialChartDatasets} />
                <div className='flex flex-initial flex-row justify-around'>
                    {Object.keys(counts).map((data, index) => (
                        <div key={index} style={cyrcleStyle}>{index +1}</div>
                    ))}
                </div>
            </div>
            <div className="flex-initial w-fit max-w-[50%] min-w-[40%] h-fit p-2 border-[1px] border-[#1a73e8] rounded-lg"> 
                <table style={tableStyle} className='border-separate pt-0'>
                    <thead>
                        <tr className='font-bold'> 
                            <th scope="col" className=' text-start'>Ответ</th>
                            <th scope="col" className=' text-center'>Всего</th>
                            <th scope="col" className=' text-center'>Процент</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(counts).map((data, index) => (
                            <Answer key={index} answerNum={index} counts={counts} initialChartDatasets={initialChartDatasets} />
                        ))}
                    </tbody>
                    <div className='h-[7px]'></div>
                </table>
            </div>
        </div>
    );
}

const cyrcleStyle = {
    borderWidth: "1px",
    borderColor: "rgb(59 130 246)",
    borderRadius: "9999px",
    textAlign: "center",
    color: "rgb(59 130 246)",
    width: "28px",
    height: "28px",
    padding: "0px"
};

const tableStyle = {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
};


function Answer({ key, answerNum, counts, initialChartDatasets }) {
    const totalCount = Object.values(counts).reduce((total, count) => total + count, 0);
    const questionKey = Object.keys(counts)[answerNum];
    const count = counts[questionKey];
    const percentage = totalCount > 0 ? Math.round((count / totalCount) * 100) : 0;

    initialChartDatasets[answerNum] = {
        label: answerNum +1,
        data: [count],
        backgroundColor: `hsl(200, 98%, ${100 - answerNum * 15 - 10}%)`, // `hsl(216, 98%, ${100 - answerNum * 15}%)`
    };
    return (
        <tr key={key} className={`bg-transparent p-5  m-0 `}>
            <td className={`text-center`}><div className='flex items-center gap-2 '><div style={cyrcleStyle} className='flex items-center p-2'>{answerNum +1}</div> <span className='text-start'>{questionKey}</span></div></td>
            <td className={`text-center`}>{count}</td>
            <td className={`text-center`}>{percentage}%</td>
        </tr>
    );
}